* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

// =============== COLOR VARIABLES ==================

:root {
  --bg-color: #f3f3f3;
  --btn-bg: #7422e7;
  --text-primary: #aba8a8;
  --active-menu: RGBA(174, 234, 0, 1);

  --th-color: #6c6c6c;
  --td-color: #111111;
}

.btn-style {
  margin: 10px 10px;
  border: none;
  border-radius: 5px;
  color: #000000;
  background-color:#daba48;
  height: 25px;
  font-size: 13px;
  font-family: sans-serif;
  font-weight: 500;
  padding: 0px 10px;
  margin-left: 20px;
}

.inputStyle {
  height: 36px;
  border-radius: 5px;
  padding-left: 30px;
  &:focus {
    box-shadow: none;
  }
}

.cx-PageNotFound {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  h1 {
    color: red;
  }
}
.headingone {
  background-color: #7422e7;
}

.card-style {
  background-color: #fff;

  padding: 30px;

  align-items: center;
  justify-content: center;
}

.offcanvas.offcanvas-start {
  top: 70px;
}

table {
  thead {
  }
  th,
  td {
    font-size: 14px;
  }

  td {
  }
}
.form-switch .form-check-input {
  height: 17px;
  width: 30px !important;
  cursor: pointer;
}

.icon-input-login {
  position: absolute;
  left: 15px;
  top: 10px;

  img {
    height: 20px;
  }
}

.login-wrapper-inner {
  padding: 0 100px;
}
