.products {
  h1 {
    margin-bottom: 70px;
  }
  .filter {
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;
    align-items: center;
    width: 100%;
    .sub-filter {
      border: 1px solid #ccc;
      padding: 5px;
      border-radius: 5px;
      display: flex;
      gap: 30px;
      .price-dropdown {
        border: 1px solid #ccc;
        padding: 10px;
        background: #fff;
        position: absolute;
        z-index: 10;
        width: 300px;
        margin-top: 30px;
        display: flex;
        flex-direction: column;
        gap: 20px;
      }

      .price-inputs {
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
      }

      .price-input {
        display: flex;
        align-items: center;
      }

      .price-input span {
        margin-right: 5px;
      }

      .price-input input {
        width: 100px;
        padding: 5px;
        border: 1px solid #ccc;
      }

      button {
        background: none;
        border: none;
        color: #007bff;
        cursor: pointer;
        padding: 0;
        margin: 0;
      }

      button:hover {
        text-decoration: underline;
      }

      p {
        display: flex;
        gap: 5px;
        align-items: center;
        font-size: 14px;
        color: grey;
        margin: unset;
      }
      .sec:hover {
        color: rgb(26, 25, 25);
      }
      select {
        border: unset;
        outline: none;
        color: grey;
        font-size: 14px;
      }
    }
  }
  .selected-filters {
    .filter-tag {
      display: flex;
      gap: 20px;
      margin-bottom: 20px;
      align-items: center;

      .round {
        border: 1px solid grey;
        padding: 5px 20px;
        border-radius: 25px;
        display: flex;
        width: 200px;
        justify-content: space-between;
        align-items: center;
        button {
          background-color: transparent;
          border: none;
          outline: none;
          font-size: 15px;
        }
      }
      button {
        background-color: transparent;
        border: none;
        outline: none;
        border-bottom: 1px solid grey;
        font-size: 15px;

        height: 20px;
      }
    }
  }
  .collection-product-display {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 10px;

    .collection-product-card {
      display: flex;
      flex-direction: column;
      gap: 10px;

      .collection-product-image-container {
        position: relative;

        img.collection-default {
          height: 290px;
          width: 100% !important;
          object-fit: cover;
          opacity: 1;
          transition: opacity 0.5s ease-in-out;
        }

        .sale-container {
          position: absolute;
          bottom: 5px;
          left: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 50px;
          background-color: black;
          border-radius: 25px;
          color: aqua;
          font-size: 15px;
        }

        .delete-container {
          position: absolute;
          
        padding:  5px 5px 5px 5px;
          // left: 20px;
          // top: 20px;
          display: flex;
          align-items: center;
          cursor: pointer;
        
          background-color: rgb(245, 240, 240);
          // border-radius: 15px;
          color: rgb(8, 8, 6);
          font-size: 15px;
        }

        .edit-container {
          position: absolute;
        
        padding:  5px 10px 5px 10px;
          left: 90px;
          // top: 20px;
          display: flex;
          align-items: center;
          
        
          background-color: rgb(246, 238, 238);
          // border-radius: 15px;
          color: rgb(8, 8, 6);;
          font-size: 15px;
        }

        &:hover {
          img.default {
            opacity: 0;
          }

          img.hover {
            opacity: 1;
          }
        }
      }

      .product-name {
        font-size: 14px;
        color: grey;
        max-width: 250px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .Roundcolor {
        background-color: #007bff;
        height: 10px;
        width: 10px;
        border-radius: 20px;
        margin-left: 5px;
        align-items: center;
        display: flex;
        margin-top: 1px;
      }
      .Roundcolorred {
        background-color: #f80000;
        height: 10px;
        width: 10px;
        border-radius: 20px;
        margin-left: 5px;
        align-items: center;
        display: flex;
        margin-top: 1px;
      }
      .product-price-details {
        display: flex;
        gap: 20px;
        align-items: center;

        .price {
          color: rgb(0, 0, 0);
          font-size: 15px;

          span {
            margin-right: 10px;
          }
        }

        .discounted-price {
          font-size: 16px;
          font-weight: 400;

          span {
            margin-right: 10px;
          }
        }
      }
      &:hover {
        .product-name {
          h6 {
            display: inline-block;
            border-bottom: 1px solid black;
          }
        }
      }
    
    }
  }
}
@media (max-width: 1150px) {
  .products {
    .collection-product-display {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
}
@media (max-width: 990px) {
  .products {
    .collection-product-display {
      max-width: 700px;
      grid-template-columns: 1fr 1fr;
    }
  }
}
@media (max-width: 431px) {
  .products {
    .filter {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
    }
    .selected-filters {
      .filter-tag {
        gap: 10px;
      }
    }
    margin-top: 50px;
    margin-top: 50px;
    margin-bottom: 30px;
    .collection-product-display {
      .collection-product-card {
        display: flex;
        flex-direction: column;
        gap: 10px;
        width: 150px;

        .collection-product-image-container {
          position: relative;

          img.collection-default {
            height: 180px;
            width: 100% !important;
            object-fit: cover;
            opacity: 1;
            transition: opacity 0.5s ease-in-out;
          }

          .sale-container {
            position: absolute;
            bottom: 5px;
            left: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 50px;
            background-color: black;
            border-radius: 25px;
            color: aqua;
            font-size: 15px;
          }

          &:hover {
            img.default {
              opacity: 0;
            }

            img.hover {
              opacity: 1;
            }
          }
        }

        .product-name {
          h6 {
            font-size: 15px;
          }
        }

        .product-price-details {
          display: flex;
          gap: 10px;
          align-items: center;

          .price {
            color: lightgray;
            font-size: 13px;

            span {
              margin-right: 10px;
              font-size: 13px;
            }
          }

          .discounted-price {
            font-weight: 400;
            font-size: 13px;

            span {
              margin-right: 10px;
            }
          }
        }
        &:hover {
          .product-name {
            h6 {
              display: inline-block;
              border-bottom: 1px solid black;
            }
          }
        }
      }
    }
  }
}
