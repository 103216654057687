/* Base styles for sidebar */
.sidebar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 250px; /* Default width for desktop */
  background-color: #f8f9fa; /* Background color */
  height: 100vh; /* Full height */
  position: fixed; /* Fixed position */
  top: 0;
  left: 0;
  z-index: 1000; /* Ensure it's on top */
}

.mobile-sidebar {
  display: none; /* Hide on desktop */
}

.menu-wrapper {
  display: flex;
  flex-direction: column;
}

.menu {
  padding: 15px;
  cursor: pointer;
  color: #333;
  text-align: center;
  transition: background-color 0.3s;
}

.menu.active-menu {
  background-color: #0b0b0b;
  color: white;
}

.logout {
  padding: 15px;
  cursor: pointer;
  color: black;
  text-align: center;
  transition: background-color 0.3s;
}

.logout:hover {
  background-color: #e9ecef;
}

/* Styles for mobile sidebar */
@media (max-width: 768px) {
  .sidebar {
    display: none; /* Hide on mobile */
  }

  .mobile-sidebar {
    display: block; /* Show on mobile */
  }

  .mobile-sidebar .offcanvas-body {
    padding: 0; /* Remove padding */
  }

  .mobile-sidebar .sidebar {
    width: 100%; /* Full width on mobile */
    position: relative; /* Relative position for Offcanvas */
  }
}

/* Styling for logo */
.logomaindiv {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.blacklogoimage {
  max-width: 150px;
  height: auto;
}

.offcanvas-body {
  display: flex;
  flex-direction: column;
}
